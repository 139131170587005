import "./polyfill";
import { sendBeacon } from "./user-timing";
import { sendData } from "./rum";
import "./rum-metrics";

declare global {
  interface Window {
    o_scale: {
      userTiming: {
        sendBeacon: typeof sendBeacon;
      };
      rum: {
        sendData: typeof sendData;
      };
    };
  }
}

window.o_scale ||= {} as never;
window.o_scale.userTiming = { sendBeacon };
window.o_scale.rum = { sendData };
