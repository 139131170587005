import * as util from "./beacon-util";
import {
  IMetric,
  METRIC_TYPE,
  normalizeBrowser,
  RawRumData,
  readBeaconServicePath,
  RumData,
} from "./beacon-util";
import { trackingEndpointLogic } from "./tracking";
import { rumArray } from "./filter-list";

const toggleNameRum = "SCALE_SendRumData";

/**
 *
 *
 *
 *
 *
 */
function transformForBeaconService(rumDataRaw: RawRumData): RumData {
  try {
    return {
      deviceBrowser: normalizeBrowser(rumDataRaw.deviceBrowser),
      deviceOS: <string>rumDataRaw.deviceOS,
      pageCluster: <string>rumDataRaw.pagecluster,
      rum_deviceType: <string>rumDataRaw.rum_deviceType,
      rum_deviceOrientation: <string>rumDataRaw.rum_deviceOrientation,
      rum_domComplete: <number>rumDataRaw.rum_domComplete,
      beaconType: "rum",
      rumMetrics: <IMetric[]>(
        (<unknown>util.filterByAllowedNames(rumDataRaw, rumArray))
      ),
      requestTime: Date.now(),
    };
    /*                                                         */
  } catch (e) {
    /*                    */
  }
}

export function readBeaconServicePathRum(): string {
  return readBeaconServicePath() + "rum";
}

/**
 *
 *
 *
 */
export function sendData(rawRumData: RawRumData) {
  trackingEndpointLogic(rawRumData);
  util.sendValidBeacon(
    transformForBeaconService(rawRumData),
    readBeaconServicePathRum(),
    toggleNameRum,
    METRIC_TYPE.rum,
  );
}
